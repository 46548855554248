<template>
  <div class="stat-box">
    <div class="stat-box__header">
      <p-icon size="medium" :icon="icon" />
      <p-headline size="5">{{ headline }}</p-headline>
    </div>

    <p-headline v-if="!loading" size="2">
      {{ value }}
    </p-headline>
    <div v-else class="stat-box__loader">
      <p-skeleton-loader type="text"> <p-headline size="2">&nbsp;</p-headline></p-skeleton-loader>
    </div>
    <span class="stat-box__label" v-if="label">{{ label }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly headline!: string;
  @Prop({ type: String, required: true }) public readonly icon!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly loading!: boolean;
  @Prop({ type: [String, Number], required: false, default: undefined }) public readonly value?: string;
  @Prop({ type: String, required: false }) public readonly label?: string;
}
</script>

<style lang="scss" scoped>
.stat-box {
  display: flex;
  width: 100%;
  min-width: 240px;
  padding: var(--base-size-700) 0px;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-size-small);
  background: var(--color-background-layer-1);

  &__header {
    display: flex;
    align-items: center;
    gap: var(--gap-size-small);
  }

  &__label {
    font-size: var(--font-size-50);
  }

  &__loader {
    width: 100px;
  }
}
</style>
