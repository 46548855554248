<template>
  <p-form-element
    type="textarea"
    :disabled="disabled"
    :readonly="readonly"
    :label="label"
    :help-text="helpText"
    :error="error"
    :size="size"
    :ai-assistant="aiAssistant"
    :modelValue="modelValue ?? ''"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <div class="textarea" :class="{ 'textarea--error': error }">
      <textarea
        class="textarea__input"
        :value="modelValue ?? ''"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxLength"
        autocomplete="off"
        rows="5"
        @input="onInput"
        @keyup="$emit('keyup', $event)"
        @update:modelValue="$emit('update:modelValue', $event)"
      ></textarea>
      <span class="textarea__character-counter" v-if="maxLength">
        {{ (modelValue ?? '').length }} / {{ maxLength }}
      </span>

      <div class="textarea__resizer"><p-icon size="large" icon="union" /></div>
    </div>
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({ type: Number, required: false, default: undefined }) public readonly maxLength?: number;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String as PropType<'medium' | 'large' | 'extra-large'>, required: false, default: 'extra-large' })
  public readonly size!: 'medium' | 'large' | 'extra-large';

  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly aiAssistant!: boolean;

  public onInput(evt: Event) {
    if (evt.target instanceof HTMLTextAreaElement) {
      this.$emit('update:modelValue', evt.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .textarea__input {
    display: block;
    width: 100%;
    appearance: none;
    border: 1px solid var(--field-color-border-default);
    background-color: var(--field-color-background-default);
    padding: var(--base-size-100) var(--base-size-200);
    border-radius: var(--field-border-radius-default);
    resize: vertical;
    min-height: 108px;

    @include component-text-default;
    color: var(--text-color-subdued);

    &:hover {
      background-color: var(--field-color-background-hover);
    }

    &:disabled {
      border-color: var(--field-color-border-disabled);
      background-color: var(--field-color-background-disabled);
      color: var(--text-color-disabled);
      cursor: not-allowed;
    }
  }

  &--error {
    > .textarea__input {
      border-color: var(--color-border-negative);
    }
  }

  &__resizer {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: var(--field-color-background-default);
    pointer-events: none;
  }

  &__character-counter {
    width: 100%;
    margin-top: 4px;
    @include text-body-small;
    display: flex;

    flex-direction: row-reverse;
    align-self: flex-end;
    margin-top: 4px;
  }
}
</style>
