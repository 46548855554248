<template>
  <div
    class="media-card"
    :class="[
      `media-card--${object.type}`,
      {
        'media-card--selection': selection,
        'media-card--selected': object.selected,
        'media-card--dragging': dragging,
        'media-card--drag-over': dragOver
      }
    ]"
    @click="select()"
    @drop="drop"
    @dragstart="dragStart"
    @dragover="onDragOver"
    @dragleave="dragLeave"
    @dragend="dragEnd"
    :draggable="!isFastTrack && !showCropper && !showRename && !showDeleteConfirm"
  >
    <div class="media-card__header">
      <p-icon v-if="object.type === 'folder'" size="extra-large" icon="folder" />

      <p-tooltip v-else-if="imageDimensions" display="block" :text="imageDimensions">
        <p-image :src="object.file" max-height="122" max-width="100%" />
      </p-tooltip>

      <p-image v-else-if="object.image" :src="object.file" max-height="122" max-width="100%" />

      <p-icon v-else size="large" icon="file" />

      <div class="media-card__checkbox" v-if="!isFastTrack" @click.prevent="object.selected = !object.selected">
        <p-checkbox v-model="object.selected" />
      </div>
    </div>

    <div class="media-card__body">
      <div class="media-card__info">
        <div class="media-card__attribute" v-if="object.type === 'file'">File name</div>
        <p-tooltip display="block" :text="object.name" v-if="object.name.length > 20">
          <div class="media-card__name">
            <template v-for="(part, index) in highlightedName">
              <mark :key="index" v-if="part.highlight">{{ part.text }}</mark>
              <template v-else>{{ part.text }}</template>
            </template>
          </div>
        </p-tooltip>
        <div class="media-card__name" v-else>
          <template v-for="(part, index) in highlightedName">
            <mark :key="index" v-if="part.highlight">{{ part.text }}</mark>
            <template v-else>{{ part.text }}</template>
          </template>
        </div>
      </div>

      <div class="media-card__tools">
        <p-tooltip text="Rename" v-if="!isFastTrack" v-device-desktop>
          <p-button
            color-type="tertiary"
            size="medium"
            icon="edit"
            :disabled.prop="deleting || moving"
            @click.stop="showRename = true"
          />
        </p-tooltip>

        <p-tooltip text="Crop" v-if="canCrop" v-device-desktop>
          <p-button
            color-type="tertiary"
            size="medium"
            icon="crop"
            :disabled.prop="deleting || moving"
            @click.stop="showCropper = true"
          />
        </p-tooltip>

        <p-tooltip text="Copy to clipboard" v-if="object.type === 'file'">
          <p-button
            color-type="tertiary"
            size="medium"
            icon="clipboard"
            :disabled.prop="deleting || moving"
            @click.stop="copy()"
            :loading.prop="copySuccess"
            :loading-success.prop="copySuccess"
          />
        </p-tooltip>

        <p-tooltip text="Download" v-if="!isFastTrack">
          <p-button
            color-type="tertiary"
            size="medium"
            icon="download"
            :disabled.prop="deleting || downloading || moving"
            :loading.prop="downloading"
            :loading-success.prop="downloadingSuccess"
            @click.stop="download()"
          />
        </p-tooltip>

        <p-tooltip text="Locate images in folder" v-if="object.type === 'folder' && selectionMode === 'folder'">
          <p-button
            color-type="tertiary"
            size="medium"
            icon="folder-plus"
            :loading.prop="selecting"
            :disabled.prop="selecting"
            @click.stop="select(true)"
          />
        </p-tooltip>

        <p-context-menu v-if="searchTerm.length > 0" :close-on-click="false">
          <template #activator>
            <p-button color-type="tertiary" icon="more-vertical" size="medium" />
          </template>

          <p-button
            color-type="tertiary"
            size="medium"
            :loading="deleting"
            :disabled="moving || deleting || showDeleteConfirm"
            @click.stop="!deleting && !moving && !showDeleteConfirm ? $emit('goToFolder', object) : null"
          >
            Go to folder
          </p-button>

          <p-button
            color-type="tertiary"
            size="medium"
            :loading="deleting"
            :disabled="moving"
            @click.stop="deleteObject()"
          >
            Delete
          </p-button>
        </p-context-menu>

        <p-tooltip text="Delete" v-else-if="!isFastTrack">
          <p-button
            color-type="tertiary"
            size="medium"
            icon="trash"
            :loading="deleting"
            :disabled="moving"
            @click.stop="deleteObject()"
          />
        </p-tooltip>
      </div>
    </div>

    <p-modal-confirm
      :show.prop="true"
      v-if="showDeleteConfirm"
      confirm-text="delete"
      :callback.prop="deleteObjectConfirmed"
      @close-request="showDeleteConfirm = false"
    />

    <modal-rename-multiple
      v-if="showRename"
      :objects="[object]"
      @renamed="onRenamed"
      @close-request="showRename = false"
    />

    <cropper
      v-if="showCropper"
      :folder="folder"
      :object="object"
      @close-request="showCropper = false"
      @cropped="$emit('cropped')"
    />
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MediaObject } from './types';
import { AppRequest } from '@/app_request';
import { copyToClipboard } from '@/utility';
import Cropper from './Cropper.vue';
import ModalRenameMultiple from './ModalRenameMultiple.vue';
import { MediaResource } from '@/types/api/media';
import { useWorkspaceStore } from '@/store/workspaceStore';

interface Part {
  text: string;
  highlight: boolean;
}

@Component({
  components: { Cropper, ModalRenameMultiple }
})
export default class extends Vue {
  @Prop({ type: Object as PropType<MediaObject>, required: true }) public readonly object!: MediaObject;
  @Prop({ type: Boolean, required: false, default: false }) public readonly selection!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly selectionMode!: 'file' | 'folder';
  @Prop({ type: String, required: false, default: '' }) public readonly searchTerm!: string;
  @Prop({ type: Object as PropType<MediaObject>, required: false, default: undefined })
  public readonly folder?: MediaObject;

  public showRename = false;

  public deleting = false;
  public copySuccess = false;
  public showDeleteConfirm = false;
  public showCropper = false;

  public downloading = false;
  public downloadingSuccess = false;

  public moving = false;
  public dragging = false;
  public dragOver = false;

  public imageDimensions = '';
  public selecting = false;

  public get highlightedName(): Part[] {
    const parts: Part[] = [];
    let { searchTerm } = this;
    const name = this.object.name;

    // Trim the search term to remove leading and trailing whitespace
    searchTerm = searchTerm.trim();

    if (!searchTerm) {
      return [{ text: name, highlight: false }];
    }

    let pos = name.toLowerCase().indexOf(searchTerm.toLowerCase());
    let currentIndex = 0;

    while (pos !== -1) {
      if (currentIndex < pos) {
        parts.push({ text: name.substring(currentIndex, pos), highlight: false });
      }
      const endPos = pos + searchTerm.length;
      parts.push({ text: name.substring(pos, endPos), highlight: true });

      currentIndex = endPos;
      pos = name.toLowerCase().indexOf(searchTerm.toLowerCase(), currentIndex);
    }

    if (currentIndex < name.length) {
      parts.push({ text: name.substring(currentIndex), highlight: false });
    }

    return parts;
  }

  public copy() {
    this.copySuccess = true;

    const workspaceStore = useWorkspaceStore();

    if (this.object.file) {
      copyToClipboard(
        (this.object.file.startsWith('/files')
          ? window.location.protocol + '//' + (workspaceStore.workspace?.root_domain ?? window.location.host)
          : '') + this.object.file
      );
    }

    setTimeout(() => {
      this.copySuccess = false;
    }, 1500);
  }

  public onRenamed(newNameMap: Record<string, string>) {
    this.object.name = newNameMap[this.object.id];
  }

  public dragStart(e: DragEvent) {
    this.dragging = true;
    e.dataTransfer?.setData('text/media-id', this.object.id);
  }

  public dragEnd() {
    this.dragging = false;

    if (localStorage.getItem('media-drop') === this.object.id) {
      localStorage.removeItem('media-drop');

      this.$emit('before-move', this.object.id);
    }
  }

  public onDragOver(e: DragEvent) {
    if (!e.dataTransfer) {
      return;
    }

    if (e.dataTransfer && this.object.type === 'folder') {
      this.dragOver = true;
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
    }
  }

  public dragLeave() {
    this.dragOver = false;
  }

  public drop(e: DragEvent) {
    if (!e.dataTransfer) {
      return;
    }

    e.preventDefault();
    this.dragOver = false;

    const mediaId = e.dataTransfer.getData('text/media-id');

    if (mediaId && e.dataTransfer && this.object.type === 'folder' && mediaId !== this.object.id) {
      localStorage.setItem('media-drop', mediaId);
      this.moveMediaToThis(mediaId);
    }
  }

  private async moveMediaToThis(mediaId: string) {
    this.moving = true;

    const existingObject = (await AppRequest.get<{ data: MediaResource }>(`/api/v1/media/${mediaId}`)).data.data;
    existingObject.folder_id = this.object.id;

    await AppRequest.put(`/api/v1/media/${mediaId}`, {
      name: existingObject.name,
      folder_id: this.object.id,
      ...(existingObject.file && { file: existingObject.file })
    });

    this.moving = false;
  }
  public deleteObject() {
    this.showDeleteConfirm = true;
  }

  public async deleteObjectConfirmed() {
    this.deleting = true;

    await AppRequest.delete(`/api/v1/media/${this.object.id}`);

    this.$emit('deleted', this.object);
  }

  public select(folderSelection = false) {
    if (this.deleting || this.showDeleteConfirm || this.moving) {
      return;
    }

    this.selecting = true;
    this.$emit('select', this.object, folderSelection);
  }

  public async download() {
    this.downloading = true;

    const zipUrl = (
      await AppRequest.post<{ url: string }>('/api/v1/media/download', {
        media_ids: [this.object.id]
      })
    ).data.url;

    window.location.href = zipUrl;

    this.downloadingSuccess = true;

    setTimeout(() => {
      this.downloading = this.downloadingSuccess = false;
    }, 1500);
  }

  public get canCrop() {
    return (
      this.object.type === 'file' &&
      this.object.image &&
      (this.object.file?.endsWith('.png') || this.object.file?.endsWith('.jpg') || this.object.file?.endsWith('.jpeg'))
    );
  }

  public get isFastTrack() {
    return !!this.$route.params.fastTrackHash;
  }

  public getImageDimensions(file: string) {
    const img = new Image();
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      this.imageDimensions = `${width}x${height}`;
    };
    img.src = file;
  }

  public mounted() {
    if (this.object.image && this.object.file) {
      const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];

      // Extract the file extension from the file path
      const fileExtension = this.object.file.split('.').pop();

      if (fileExtension && validImageExtensions.includes(fileExtension.toLowerCase())) {
        this.getImageDimensions(this.object.file);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.media-card {
  display: flex;
  flex-direction: column;
  width: 210px;
  background-color: var(--color-background-layer-2);
  border-radius: var(--border-radius-small);
  flex-grow: 0;
  border: 1px solid var(--color-base-200);
  transition: background-color 175ms linear;

  &--dragging,
  &--drag-over {
    background-color: var(--color-hero-200);
  }

  &--selected {
    box-shadow: 0 0 0 2px var(--color-border-decorative-inverted) inset;
    border-color: transparent;

    .media-card__body {
      border: none;
      width: calc(100% - 4px);
      margin-left: 2px;
      margin-bottom: 2px;

      border-left: none !important;
      border-right: none !important;
      border-bottom: none !important;
    }
  }

  &--dragging {
    .media-card__tools,
    .media-card__checkbox {
      opacity: 0;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    height: 142px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    position: relative;

    color: var(--icon-color-subdued);
  }

  &__body {
    display: flex;
    width: 100%;
    padding: var(--base-size-100) var(--base-size-300) var(--base-size-300) var(--base-size-300);
    flex-direction: column;
    align-items: center;
    gap: var(--gap-size-extra-small);
    flex-grow: 1;
    justify-content: space-between;
  }

  &__checkbox {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--base-size-100);
    cursor: pointer;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-size-extra-small);
    align-self: stretch;
  }

  &__tools {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__attribute {
    @include component-text-small;
    color: var(--text-color-help);
  }

  &__name {
    @include component-text-headline;

    overflow: hidden;
    color: var(--text-color-headline);
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 36px;
    width: 100%;
  }

  &--file {
    > .media-card__body {
      background-color: var(--color-background-layer-1);
      border-left: 2px solid var(--color-background-layer-1);
      border-right: 2px solid var(--color-background-layer-1);
      border-bottom: 2px solid var(--color-background-layer-1);
    }
  }

  &--selection {
    cursor: pointer;
  }

  &--folder {
    cursor: pointer;

    > .media-card__body {
      padding-top: 0;

      > .media-card__info {
        text-align: center;

        .media-card__name {
          text-align: center;
          line-height: 36px;
        }
      }
    }
  }
}

@include for-mobile-only {
  .media-card {
    width: calc(50% - (var(--gap-size-large) / 2));

    &__tools {
      gap: var(--gap-size-extra-small);
    }
  }
}
</style>
