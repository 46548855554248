<template>
  <Fragment>
    <div class="fast-track-header">
      <div class="fast-track-header__inner">
        <p-link href="/">
          <img :src="logo" :width="125" :height="30" />
        </p-link>
      </div>
    </div>

    <p-modal-loader v-if="waitingForResource && !showPasswordPrompt" />

    <p-page v-if="showPasswordPrompt || fastTrackResource || campaignModel || noAccess">
      <template v-if="noAccess">
        <p-message
          type="negative"
          headline="Access denied"
          description="This can either be because of IP or date restrictions."
        />
      </template>

      <template v-else-if="showPasswordPrompt">
        <p-row justify-content="center">
          <p-column size="large">
            <p-container>
              <p-headline size="3">Login to FastTrack</p-headline>
              <p-form-input
                v-model="password"
                label="Password"
                type="password"
                size="large"
                autofocus
                :error="loginError"
                @keydown.enter="login()"
              />

              <p-button size="medium" @click="login()" :loading.prop="waitingForLoginResponse">Login</p-button>
            </p-container>
          </p-column>
        </p-row>
      </template>
      <template v-else>
        <p-nav-tabs
          headline="Fast track"
          :subheadline="navSubHeadline"
          :items="tabs"
          :sub-items="subTabs"
          :view-campaign-link="fastTrackResource?.campaign?.demo_url ?? campaignModel?.resource?.demo_url"
        />

        <layout-element-blueprint v-if="blueprintUrl" :url="blueprintUrl" />
      </template>
    </p-page>
  </Fragment>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import logo from '@/assets/images/logo.svg';
import { TabsNavItem } from '@/components/ui/custom/nav-tabs/types';
import { CampaignFastTrackResource } from '@/types/api/campaign';
import { AppRequest } from '@/app_request';
import axios from 'axios';
import { Route } from 'vue-router';
import { CampaignModel } from '@/models/campaign';

@Component({})
export default class extends Vue {
  public logo = logo;
  public fastTrackResource: CampaignFastTrackResource | null = null;
  public campaignModel: CampaignModel | null = null;
  public waitingForResource = true;
  public showPasswordPrompt = false;
  public waitingForLoginResponse = false;
  public password = '';
  public loginError = '';
  public embedPage = '';
  public noAccess = false;

  // Navigations inside of the blueprint we want to prevent and instead overwrite the local blueprint
  // with the route. This allows local display and navigation between pages.
  public beforeRouteLeave(to: Route, _from: Route, next: () => void) {
    const tabs = this.tabs.map((tab) => tab.href);
    const subTabs = this.subTabs.map((tab) => tab.href);

    if (to.path !== '/' && !tabs.includes(to.fullPath) && !subTabs.includes(to.fullPath)) {
      this.embedPage = to.fullPath;
      return false;
    }

    this.embedPage = '';
    next();
  }

  // If we update the same route, we need to clear the embed page in order for the blueprint
  // to properly update with the new page/sub-page that is selected.
  public beforeRouteUpdate(to: Route, _from: Route, next: () => void) {
    this.embedPage = '';
    next();
  }

  @Watch('$route.params.fastTrackHash', { immediate: true })
  public async onFastTrackHashChange() {
    if (this.$route.params.fastTrackHash) {
      this.waitingForResource = true;

      try {
        this.fastTrackResource = (
          await AppRequest.get<{ data: CampaignFastTrackResource }>(
            `/api/v1/fast-track/${this.$route.params.fastTrackHash}`
          )
        ).data.data;

        this.waitingForLoginResponse = false;
        this.showPasswordPrompt = false;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 401) {
          this.waitingForResource = false;
          this.showPasswordPrompt = true;
          return;
        } else if (axios.isAxiosError(e) && e.response?.status === 410) {
          this.noAccess = true;
          this.waitingForResource = false;
          this.showPasswordPrompt = false;
          return;
        }

        throw e;
      }

      this.waitingForResource = false;
    } else if (this.$route.params.campaignId) {
      // Route is not a fast-track instance, but instead the BE authed fast track page
      this.campaignModel = await CampaignModel.fromId(Number(this.$route.params.campaignId));
      this.waitingForResource = false;
    }
  }

  public async login() {
    if (!this.password) {
      this.loginError = 'This field is required';
      return;
    }

    this.loginError = '';
    this.waitingForLoginResponse = true;

    try {
      await AppRequest.post(`/api/v1/fast-track/${this.$route.params.fastTrackHash}/login`, {
        password: this.password
      });

      this.onFastTrackHashChange();
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 400) {
        this.loginError = 'Invalid password';
        this.waitingForLoginResponse = false;
        return;
      }

      throw e;
    }
  }

  public get navSubHeadline(): string {
    if (this.campaignModel) {
      return `${this.campaignModel.resource.name} #${this.campaignModel.resource.id}`;
    }

    if (!this.fastTrackResource) {
      return '';
    }

    return `${this.fastTrackResource.campaign.name} #${this.fastTrackResource.campaign.id}`;
  }

  public get permissions() {
    if (this.campaignModel) {
      if (this.gameSettingsTabs.length) {
        return ['game_settings', 'layout', 'prizes', 'bulk_prizes', 'email', 'messages', 'section', 'flow', 'page'];
      }

      return ['layout', 'prizes', 'bulk_prizes', 'email', 'messages', 'section', 'flow', 'page'];
    }

    if (!this.fastTrackResource) {
      return [];
    }

    if (
      (this.fastTrackResource.permissions.includes('prizes') ||
        this.fastTrackResource.permissions.includes('bulk_prizes')) &&
      this.fastTrackResource.ip_list.length === 0
    ) {
      return this.fastTrackResource.permissions.filter((permission) => {
        return permission !== 'prizes' && permission !== 'bulk_prizes';
      });
    }

    return this.fastTrackResource.permissions;
  }

  public get tabs(): TabsNavItem[] {
    const navItems: TabsNavItem[] = [];

    if (this.permissions.includes('game_settings') && this.gameSettingsTabs.length > 0) {
      navItems.push({
        text: 'Game settings',
        href: this.gameSettingsTabs[0].href,
        active:
          this.$route.query.page === 'game_settings' ||
          (!this.$route.query.page && this.defaultPage === 'game_settings')
      });
    }

    if (this.permissions.includes('layout')) {
      navItems.push({
        text: 'Layout',
        href: `${this.basePath}?page=layout`,
        active: this.$route.query.page === 'layout' || (!this.$route.query.page && this.defaultPage === 'layout')
      });
    }

    if (this.permissions.includes('prizes')) {
      navItems.push({
        text: 'Prizes',
        href: `${this.basePath}?page=prizes`,
        active: this.$route.query.page === 'prizes' || (!this.$route.query.page && this.defaultPage === 'prizes')
      });
    }

    if (
      this.permissions.includes('bulk_prizes') &&
      (this.fastTrackResource?.campaign.bulk_prize_integration?.id ||
        this.campaignModel?.resource.bulk_prize_integration?.id)
    ) {
      navItems.push({
        text: 'Bulk prizes',
        href: `${this.basePath}?page=bulk_prizes`,
        active:
          this.$route.query.page === 'bulk_prizes' || (!this.$route.query.page && this.defaultPage === 'bulk_prizes')
      });
    }

    if (this.permissions.includes('email')) {
      navItems.push({
        text: 'Email',
        href: `${this.basePath}?page=email&sub-page=settings`,
        active: this.$route.query.page === 'email' || (!this.$route.query.page && this.defaultPage === 'email')
      });
    }

    if (this.permissions.includes('messages')) {
      navItems.push({
        text: 'Messages',
        href: `${this.basePath}?page=messages`,
        active: this.$route.query.page === 'messages' || (!this.$route.query.page && this.defaultPage === 'messages')
      });
    }

    if (
      (this.permissions.includes('section') ||
        this.permissions.includes('flow') ||
        this.permissions.includes('page')) &&
      this.contentTabs.length > 0
    ) {
      navItems.push({
        text: 'Content',
        href: this.contentTabs[0].href,
        active: this.$route.query.page === 'content' || (!this.$route.query.page && this.defaultPage === 'content')
      });
    }

    return navItems;
  }

  public get basePath(): string {
    return this.fastTrackResource
      ? `/campaign/fast-track/${this.$route.params.fastTrackHash}`
      : `/campaign/${this.$route.params.campaignType}/${this.$route.params.campaignId}/fast-track`;
  }

  public get subTabs(): TabsNavItem[] {
    const page = this.$route.query.page ?? this.defaultPage;

    switch (page) {
      case 'email':
        return this.emailSubTabs;

      case 'game_settings':
        return this.gameSettingsTabs;

      case 'content':
        return this.contentTabs;
    }

    return [];
  }

  public get contentTabs(): TabsNavItem[] {
    const tabItems: TabsNavItem[] = [];

    if (this.permissions.includes('section')) {
      tabItems.push({
        text: 'Sections',
        href: `${this.basePath}?page=content&sub-page=section`,
        active: this.$route.query['sub-page'] === 'section'
      });
    }

    if (this.permissions.includes('flow')) {
      tabItems.push({
        text: 'Flow pages',
        href: `${this.basePath}?page=content&sub-page=flow`,
        active: this.$route.query['sub-page'] === 'flow'
      });
    }

    if (this.permissions.includes('page')) {
      tabItems.push({
        text: 'Popovers',
        href: `${this.basePath}?page=content&sub-page=page`,
        active: this.$route.query['sub-page'] === 'page'
      });
    }

    return tabItems;
  }

  public get gameSettingsTabs(): TabsNavItem[] {
    const gameSettingsTabs =
      this.fastTrackResource?.campaign.game_settings_tabs ?? this.campaignModel?.resource.game_settings_tabs ?? null;

    if (!gameSettingsTabs?.length) {
      return [];
    }

    const gameSubPage = String(this.$route.query['sub-page'] ?? gameSettingsTabs[0].id);

    return gameSettingsTabs.map((tab) => {
      return {
        text: tab.text,
        href: `${this.basePath}?page=game_settings&sub-page=${tab.id}`,
        active: gameSubPage === tab.id
      };
    });
  }

  public get emailSubTabs(): TabsNavItem[] {
    const tabItems: TabsNavItem[] = [
      {
        text: 'Settings',
        href: `${this.basePath}?page=email&sub-page=settings`,
        active: this.$route.query['sub-page'] === 'settings'
      },
      {
        text: 'Winner',
        href: `${this.basePath}?page=email&sub-page=winner`,
        active: this.$route.query['sub-page'] === 'winner'
      }
    ];

    if (
      this.permissions.includes('bulk_prizes') &&
      (this.fastTrackResource?.campaign.bulk_prize_integration?.id ||
        this.campaignModel?.resource.bulk_prize_integration?.id)
    ) {
      tabItems.push({
        text: 'Bulk prize email',
        href: `${this.basePath}?page=email&sub-page=bulk-prize-email`,
        active: this.$route.query['sub-page'] === 'bulk-prize-email'
      });
    }

    return tabItems;
  }

  public get defaultPage(): string {
    const navItems: string[] = [];

    if (this.permissions.includes('game_settings')) {
      navItems.push('game_settings');
    }

    if (this.permissions.includes('layout')) {
      navItems.push('layout');
    }

    if (this.permissions.includes('prizes')) {
      navItems.push('prizes');
    }

    if (this.permissions.includes('bulk_prizes')) {
      navItems.push('bulk_prizes');
    }

    if (this.permissions.includes('email')) {
      navItems.push('email');
    }

    if (this.permissions.includes('messages')) {
      navItems.push('messages');
    }

    if (
      this.permissions.includes('section') ||
      this.permissions.includes('flow') ||
      this.permissions.includes('page')
    ) {
      navItems.push('content');
    }

    return navItems[0] ?? '';
  }

  public get blueprintUrl(): string | null {
    if (this.embedPage) {
      return this.embedPage;
    }

    const gameSubPage = String(
      this.$route.query['sub-page'] ??
        this.campaignModel?.resource.game_settings_tabs[0]?.id ??
        this.fastTrackResource?.campaign.game_settings_tabs[0]?.id
    );

    const routePrefix = this.fastTrackResource ? '/fast-track' : '';
    const campaignType = this.$route.params.campaignType ?? this.fastTrackResource?.campaign_type.alias;
    const campaignId = this.$route.params.campaignId ?? this.fastTrackResource?.campaign.id;
    const bulkIntegrationId = this.fastTrackResource
      ? this.fastTrackResource.campaign.bulk_prize_integration?.id
      : this.campaignModel?.resource?.bulk_prize_integration?.id;
    const append = this.fastTrackResource ? `&fast-track=${this.$route.params.fastTrackHash}` : '';

    switch (this.$route.query.page ?? this.defaultPage) {
      case 'layout':
        return `${routePrefix}/campaign/${campaignType}/${campaignId}/layout/general?template=fast-track-embed${append}`;

      case 'prizes':
        return `${routePrefix}/campaign/${campaignType}/${campaignId}/prize/list?template=fast-track-embed${append}`;

      case 'bulk_prizes':
        return `${routePrefix}/campaign/${campaignType}/${campaignId}/integration/modify/${bulkIntegrationId}/prizes?template=fast-track-embed${append}`;

      case 'messages':
        return `${routePrefix}/campaign/${campaignType}/${campaignId}/advanced/messages?template=fast-track-embed${append}`;

      case 'email':
        switch (this.$route.query['sub-page']) {
          case 'settings':
            return `${routePrefix}/campaign/${campaignType}/${campaignId}/email/settings?template=fast-track-embed${append}`;

          case 'winner':
            return `${routePrefix}/campaign/${campaignType}/${campaignId}/email/winner?template=fast-track-embed${append}`;

          case 'bulk-prize-email':
            return `${routePrefix}/campaign/${campaignType}/${campaignId}/integration/modify/${bulkIntegrationId}/emails?template=fast-track-embed${append}`;
        }
        break;

      case 'game_settings':
        return `${routePrefix}/campaign/${campaignType}/${campaignId}/game-settings/${gameSubPage}?template=fast-track-embed${append}`;

      case 'content':
        // Viewed through admin as a signed in user
        if (this.campaignModel) {
          switch (this.$route.query['sub-page']) {
            case 'section':
              return `${routePrefix}/campaign/${campaignType}/${campaignId}/advanced/fast-track/content?type=section&template=fast-track-embed`;

            case 'flow':
              return `${routePrefix}/campaign/${campaignType}/${campaignId}/advanced/fast-track/content?type=flow&template=fast-track-embed`;

            case 'page':
              return `${routePrefix}/campaign/${campaignType}/${campaignId}/advanced/fast-track/content?type=page&template=fast-track-embed`;
          }
          break;
        } else {
          // Viewed through gated fast track hash
          switch (this.$route.query['sub-page']) {
            case 'section':
              return `${routePrefix}/campaign/${this.$route.params.fastTrackHash}/content?type=section&template=fast-track-embed`;

            case 'flow':
              return `${routePrefix}/campaign/${this.$route.params.fastTrackHash}/content?type=flow&template=fast-track-embed`;

            case 'page':
              return `${routePrefix}/campaign/${this.$route.params.fastTrackHash}/content?type=page&template=fast-track-embed`;
          }
          break;
        }
    }

    return null;
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';
@import '../../scss/mixins/devices';

.fast-track-header {
  position: relative;
  display: flex;
  justify-content: center;
  background: var(--color-background-layer-2-inverted);
  color: var(--navigation-color-text-default);
  z-index: 10;
  min-width: 1200px;
  height: 64px;

  &__inner {
    display: flex;
    width: 1200px;
    max-width: 100%;
    align-items: center;
    position: relative;

    --text-color-default: var(--navigation-color-text-default);
    --text-color-subdued: var(--navigation-color-text-default);
  }
}

@include for-mobile-only {
  .fast-track-header {
    max-width: 100%;
    min-width: 0;

    &__inner {
      padding: 0 var(--base-size-300);
    }
  }
}
</style>
