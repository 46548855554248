<template>
  <div class="text-editor" :class="{ 'text-editor--error': error }">
    <div class="text-editor__instance" ref="instance">
      <tinymce-editor
        v-if="renderEditor"
        :key="editorId"
        ref="editor"
        :value="modelValue"
        :disabled="disabled"
        :init="options"
      />
    </div>

    <p-modal-media
      v-if="showMediaModal"
      @close-request="closeMediaDialog"
      @open-request="openMediaDialog"
      :extensions="['png', 'gif', 'jpeg', 'jpg', 'svg']"
      selection
      @select="onMediaSelect"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import { EditorPageOption } from './types';
import { Iframe } from '@/iframe';

// TinyMCE Vue component is incorrectly typed and breaks the project
// if included using import syntax.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const tinymceVue = require('@tinymce/tinymce-vue').default;

@Component({
  components: { 'tinymce-editor': tinymceVue },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly modelValue!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;

  @Prop({
    type: String as PropType<'DIV' | 'P'>,
    required: false,
    default: undefined,
    validator: (value) => value === 'DIV' || value === 'P'
  })
  public readonly forcedRootBlock?: 'DIV' | 'P';

  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Array as PropType<string[]>, required: false, default: () => [] }) public readonly toolbar!: string[];

  @Prop({ type: Array as PropType<EditorPageOption[]>, required: false, default: () => [] })
  public readonly sections!: EditorPageOption[];

  @Prop({ type: Array as PropType<EditorPageOption[]>, required: false, default: () => [] })
  public readonly popovers!: EditorPageOption[];

  public editorId = 0;
  public renderEditor = false;
  public showMediaModal = false;

  public created() {
    this.$nextTick(async () => {
      await import('tinymce/tinymce');
      await import('tinymce/models/dom/model');

      await Promise.all([
        import('tinymce/skins/ui/oxide/skin.js'),
        import('tinymce/skins/ui/oxide/content.js'),
        import('tinymce/skins/ui/oxide/content.inline.js'),
        import('tinymce/skins/content/default/content.js')
      ]);

      await Promise.all([import('tinymce/themes/silver'), import('tinymce/icons/default')]);

      await Promise.all([
        import('tinymce/plugins/lists'),
        import('tinymce/plugins/link'),
        import('tinymce/plugins/code'),
        import('tinymce/plugins/image')
      ]);

      this.renderEditor = true;
    });
  }

  public openMediaDialog() {
    Iframe.emit('frame-request-full-size');
    this.showMediaModal = true;
    this.$emit('media-dialog-open');
  }

  public closeMediaDialog() {
    this.showMediaModal = false;
    this.$emit('media-dialog-close');

    Iframe.emit('frame-request-regular-size-before');

    setTimeout(() => {
      Iframe.emit('frame-request-regular-size');
    }, 475);
  }

  public onMediaSelect(path: string) {
    if (this.$refs.editor) {
      // eslint-disable-next-line
      (this.$refs.editor as any).editor.insertContent(`<img src="${path}" />`);
      this.closeMediaDialog();
    }
  }

  public get options(): object {
    let block: 'div' | 'p' | undefined = undefined;

    if (this.forcedRootBlock) {
      switch (this.forcedRootBlock) {
        case 'DIV':
          block = 'div';
          break;

        case 'P':
          block = 'p';
          break;
      }
    }

    const referenceElement = this.$refs.instance instanceof HTMLDivElement ? this.$refs.instance : this.$el;

    const defaultColor = window.getComputedStyle(referenceElement).color;
    const fontSize = window.getComputedStyle(referenceElement).fontSize;
    const lineHeight = window.getComputedStyle(referenceElement).lineHeight;

    const options: object = {
      branding: false,
      plugins: 'lists link code image',
      toolbar:
        this.toolbar.length > 0
          ? this.toolbar.join(' ')
          : 'bold italic underline blocks forecolor align | numlist bullist | link mediaSelector undo redo code',
      menubar: false,
      extended_valid_elements: 'span[*]',
      paste_as_text: true,
      statusbar: true,
      height: '400',
      convert_urls: false,
      resize: 'both',
      block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
      forced_root_block: block,
      content_style: `body {
                       color:${defaultColor};
                       font-size:${fontSize};
                       line-height:${lineHeight};
                      }
                      a {
                       color: #586f7c;
                      }
                      .mce-content-body [data-mce-selected="inline-boundary"] {
                       background-color: transparent;
                      }
                      ol, ul, li {
                        padding: 0;
                        margin: 0;
                      }
                      ul {
                        list-style-position: inside;
                        margin-bottom: 2rem;
                        list-style: disc inside none;
                      }`,

      setup: (editor: any) => {
        editor.on('init', () => {
          this.tinyMceEditLink(editor);
        });

        // eslint-disable-next-line
        (editor as any).ui.registry.addButton('mediaSelector', {
          icon: 'image',
          onAction: () => {
            this.openMediaDialog();
          }
        });

        editor.on('change input keyup', () => {
          this.$nextTick(() => {
            this.$emit('update:modelValue', editor.getContent());
          });
        });
      }
    };

    // eslint-disable-next-line
    (options as any).license_key = 'gpl';

    // eslint-disable-next-line
    (options as any).placeholder = this.placeholder;

    // eslint-disable-next-line
    (options as any).default_link_target = '_self';

    // eslint-disable-next-line
    (options as any).target_list = [
      { title: 'None', value: '' },
      { title: 'Same page', value: '_self' },
      { title: 'New window', value: '_blank' },
      { title: 'Top page', value: '_top' }
    ];

    return options;
  }

  private tinyMceEditLink(editor: any) {
    // eslint-disable-next-line
    (editor.windowManager as any).oldOpen = editor.windowManager.open;
    editor.windowManager.open = (args: object, params: object) => {
      // eslint-disable-next-line
      const modal = (editor.windowManager as any).oldOpen.apply((editor.windowManager as any).oldOpen, [args, params]);

      if ('title' in args && args.title === 'Insert/Edit Link') {
        this.modifyLinkRenderer(modal, null);
      }

      return modal; // Template plugin is dependent on this return value
    };
  }

  private modifyLinkRenderer(modal: any, urlTypeForce: null | string) {
    const types = [
      {
        id: 'url',
        label: 'URL address',
        default: true
      },
      this.popovers && this.popovers.length > 0
        ? {
            id: 'popover',
            label: 'Open popover',
            identify: (value: any) => {
              return value && (value + '').indexOf('#/?page=') === 0;
            },
            select: {
              label: 'Popover',
              options: [
                {
                  value: '',
                  text: 'Choose popover'
                },
                ...this.popovers.map((popover) => {
                  return {
                    value: popover.value,
                    text: popover.label ? popover.label : popover.text
                  };
                })
              ],
              identify: (url: any) => {
                let option = (url + '').replace('#/?page=', '');

                if (option.indexOf('#') === 0 || isNaN(parseInt(option))) {
                  option = '';
                }

                return option;
              },
              getUrlFromOption: (option: any) => {
                return option ? '#/?page=' + option : '';
              }
            }
          }
        : null,
      this.popovers && this.popovers.length > 0
        ? {
            id: 'close-popover',
            label: 'Close popover',
            identify: (value: any) => {
              return value && (value + '').indexOf('#/?close-popover=') === 0;
            }
          }
        : null,
      this.sections && this.sections.length > 0
        ? {
            id: 'section',
            label: 'Go to section',
            identify: (value: any) => {
              return value && (value + '').indexOf('#/?section=') === 0;
            },
            select: {
              label: 'Section',
              options: [
                {
                  value: '',
                  text: 'Choose section'
                },
                ...this.sections.map((section) => {
                  return {
                    value: section.value,
                    text: section.text
                  };
                })
              ],
              identify: (url: any) => {
                let option = (url + '').replace('#/?section=', '');

                if (option.indexOf('#') === 0 || isNaN(parseInt(option))) {
                  option = '';
                }

                return option;
              },
              getUrlFromOption: (option: any) => {
                return option ? '#/?section=' + option : '';
              }
            }
          }
        : null
    ].filter((val) => val !== null);

    const lastGroup = document.querySelector('.tox-form__group:last-child');

    if (lastGroup && document.querySelector('.tox-form__group--type') === null) {
      const typeSelectorElement = document.createElement('div');
      typeSelectorElement.className = 'tox-form__group tox-form__group--type';
      typeSelectorElement.innerHTML = [
        '<label class="tox-label" for="form-field_type">Type</label>',
        '<div class="tox-selectfield">',
        '<select size="1" id="form-field_type">',
        types
          .map((type) => {
            if (type) {
              return '<option value="' + type.id + '">' + type.label + '</option>';
            }

            return '';
          })
          .join(''),
        '</select>',
        '<div class="tox-selectfield__icon-js"><svg width="10" height="10"><path d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z" fill-rule="nonzero"></path></svg></div>',
        '</div>'
      ].join('');

      (lastGroup.parentNode as HTMLElement).insertBefore(
        typeSelectorElement,
        (lastGroup.parentNode as HTMLElement).childNodes[0]
      );

      const typeSelect = typeSelectorElement.querySelector('select');

      if (typeSelect !== null) {
        typeSelect.addEventListener('change', () => {
          if ('close-popover' === typeSelect.value) {
            const data = modal.getData();
            data.url.value = '#/?close-popover=1';
            modal.setData(data);
          }
          this.modifyLinkRenderer(modal, typeSelect.value);
        });
      }
    }

    let urlType: any = null;

    types.forEach((type) => {
      if (!type) {
        return;
      }

      if (urlTypeForce !== null) {
        if (type.id === urlTypeForce) {
          urlType = type;
        }

        return;
      }

      if (urlType === null && type.default) {
        urlType = type;
      }

      if (type.identify && type.identify(modal.getData().url.value)) {
        urlType = type;
      }

      if (lastGroup !== null && type.select && document.querySelector('.tox-form__group--' + type.id) === null) {
        const typeElement = document.createElement('div');
        typeElement.className = 'tox-form__group tox-form__group--' + type.id;
        typeElement.innerHTML = [
          '<label class="tox-label" for="form-field_' + type.id + '">' + type.select.label + '</label>',
          '<div class="tox-selectfield">',
          '<select size="1" id="form-field_' + type.id + '">',
          type.select.options
            .map((option) => {
              return '<option value="' + option.value + '">' + option.text + '</option>';
            })
            .join(''),
          '</select>',
          '<div class="tox-selectfield__icon-js"><svg width="10" height="10"><path d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z" fill-rule="nonzero"></path></svg></div>',
          '</div>'
        ].join('');

        const typeElementSelect = typeElement.querySelector('select');

        if (typeElementSelect !== null) {
          typeElementSelect.addEventListener('change', () => {
            if (type.select.getUrlFromOption) {
              const urlEl = document.querySelector<HTMLInputElement>('.tox-form__group:nth-child(2) input');

              if (urlEl !== null) {
                const data = modal.getData();
                data.url.value = type.select.getUrlFromOption(typeElementSelect.value);
                modal.setData(data);
                urlEl.value = data.url.value;
              }
            }
          });
        }

        (lastGroup.parentNode as HTMLElement).appendChild(typeElement);
      }
    });

    const typeSelectEl = document.querySelector<HTMLSelectElement>('.tox-form__group--type select');

    if (typeSelectEl !== null && urlType !== null) {
      typeSelectEl.value = urlType.id;
    }

    if (urlType !== null) {
      const typeElementSelectEl = document.querySelector<HTMLSelectElement>(
        '.tox-form__group--' + urlType.id + ' select'
      );

      if (typeElementSelectEl !== null && urlType.select) {
        const optionToSelect = urlType.select.identify(modal.getData().url.value);

        if (optionToSelect) {
          typeElementSelectEl.value = optionToSelect;
        }
      }
    }

    document.querySelectorAll<HTMLElement>('.tox-form__group').forEach((el) => {
      el.style.display = 'none';
    });

    const groupType = document.querySelector<HTMLElement>('.tox-form__group--type');

    if (groupType) {
      groupType.style.display = 'block';
    }

    if (urlType !== null) {
      switch (urlType.id) {
        case 'url':
          document
            .querySelectorAll<HTMLElement>(
              [
                '.tox-form__group:nth-child(2)',
                '.tox-form__group:nth-child(3)',
                '.tox-form__group:nth-child(4)',
                '.tox-form__group:nth-child(5)'
              ].join(',')
            )
            .forEach((el) => {
              el.style.display = 'block';
            });
          break;

        case 'section':
          document
            .querySelectorAll<HTMLElement>(
              ['.tox-form__group:nth-child(3)', '.tox-form__group:nth-child(4)', '.tox-form__group--section'].join(',')
            )
            .forEach((el) => {
              el.style.display = 'block';
            });
          break;

        case 'popover':
          document
            .querySelectorAll<HTMLElement>(
              ['.tox-form__group:nth-child(3)', '.tox-form__group:nth-child(4)', '.tox-form__group--popover'].join(',')
            )
            .forEach((el) => {
              el.style.display = 'block';
            });
          break;
      }
    }
  }

  @Watch('forcedRootBlock')
  private onRootBlockChange() {
    this.editorId++;
  }

  @Watch('disabled')
  private onDisabledChange() {
    this.editorId++;
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.text-editor {
  width: 100%;

  &__instance {
    @include text-body-default;
  }

  ::v-deep {
    .tox .tox-edit-area::before {
      display: none;
    }

    .tox .tox-editor-header {
      z-index: 1;
    }

    .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
      border-right: 1px solid var(--field-color-border-default);
    }

    .tox:not(.tox-tinymce-inline) .tox-editor-header {
      padding: 0;
      box-shadow: none;
      border-bottom: 1px solid var(--field-color-border-default);
    }

    .tox-tinymce {
      max-width: 100%;
      min-width: 100%;
      border: 1px solid var(--field-color-border-default);
      border-radius: var(--field-border-radius-default);
      background-color: var(--field-color-background-default);
      min-height: 145px;
    }
    textarea {
      display: none;
    }
  }

  &--error {
    ::v-deep {
      .tox-tinymce {
        border-color: var(--field-color-border-negative);
      }
    }
  }
}
</style>
