<template>
  <p-section>
    <p-row justify-content="center">
      <p-column size="extra-large">
        <p-container align-items="center">
          <p-icon icon="broom" size="extra-large"></p-icon>
          <p-headline size="3">Keep your campaigns up to date</p-headline>
          <p-container align-items="center" gap-size="extra-small">
            <p-paragraph>
              We noticed that you have always-on campaigns on your account that are more than 12 months old. If you wish
            </p-paragraph>
            <p-paragraph>
              to continue using a campaign, please create a copy of it and use the new version instead. This will keep
            </p-paragraph>
            <p-paragraph> your campaigns up-to-date and ensure optimal performance. </p-paragraph>
          </p-container>
        </p-container>
      </p-column>
    </p-row>

    <p-row justify-content="center">
      <p-tabs>
        <p-tabs-item
          v-for="tab in campaignTabs"
          :key="tab.id"
          :selected.prop="selectedCampaignTab === tab.id"
          :text="tab.label"
          size="medium"
          @select="selectedCampaignTab = String(tab.id)"
        />
      </p-tabs>
    </p-row>

    <p-modal-confirm
      :show.prop="true"
      v-if="campaignDeleteConfirm"
      :confirm-text="campaignDeleteConfirm.name"
      description="Deletion of this campaign cannot be undone!"
      :title="confirmModalTitle"
      :callback.prop="deleteCampaign"
      @close-request="campaignDeleteConfirm = null"
    />

    <p-table-client
      :pagination="false"
      :searchable="false"
      :header="[
        { id: 'name', label: 'Name', width: '240px', verticalAlign: 'top' },
        { id: 'id', label: 'ID', width: '140px', verticalAlign: 'top' },
        { id: 'creator', label: 'Creator', width: '140px', verticalAlign: 'top' },
        { id: 'creation', label: 'Creation', width: '140px', nowrap: true, verticalAlign: 'top' },
        { id: 'actions', verticalAlign: 'top' }
      ]"
      :body="AlwaysOnCampaignsBody"
      :skeleton-loader="waitingForAlwaysOnCampaigns || waitingForCampaignTypes"
      :skeleton-loader-rows="2"
      :skeleton-loader-text-lines="2"
    >
      <!-- eslint-disable-next-line -->
      <template #bodyCell="{ row, column, header }">
        <!-- Name -->
        <template v-if="header.id === 'name'">
          <p-tooltip :text="column" v-if="column && column.length > 10">
            <div class="campaign-name">{{ column }}</div>
          </p-tooltip>
          <div class="campaign-name" v-else>{{ column }}</div>

          <p-row gap-size="small">
            <p-badge v-if="row.device_type" type="info" :text="row.device_type" />
            <p-badge v-if="row.template?.content" type="info" text="Content" />
            <p-badge v-if="row.template?.layout" type="info" text="Layout" />
          </p-row>
        </template>

        <!-- ID -->
        <template v-else-if="header.id === 'id'">
          <p-paragraph>{{ column }}</p-paragraph>

          <span class="decorative-text">
            <p-paragraph typography="component-text-small" v-if="row.typeName">{{ row.typeName }}</p-paragraph>
          </span>
        </template>

        <!-- Creator -->
        <template v-else-if="header.id === 'creator'">
          <p-tooltip :text="column" v-if="column && column.length > 10">
            <div class="creator-name">{{ column }}</div>
          </p-tooltip>
          <div class="creator-name" v-else>{{ column }}</div>
        </template>

        <!-- Created on -->
        <template v-else-if="header.id === 'creation'">
          <div class="campaign-creation">
            <p-paragraph>{{ row.created_on }}</p-paragraph>

            <p-badge v-if="row.state === 'paused'" type="negative" text="Paused" />
            <p-badge v-else-if="row.state === 'live' && row.active_always" type="positive" text="Always on" />
          </div>
        </template>

        <!-- Actions -->
        <template v-else-if="header.id === 'actions'">
          <p-tooltip text="View Campaign" position="bottom">
            <p-link :href="row.demo_url" target="_blank">
              <p-button color-type="tertiary" icon="eye" size="medium" />
            </p-link>
          </p-tooltip>

          <p-tooltip v-if="row.permissions.copy" text="Copy" position="bottom">
            <p-link :href="`/campaign/copy/${row.id}`">
              <p-button color-type="tertiary" icon="copy" size="medium" />
            </p-link>
          </p-tooltip>

          <p-tooltip v-if="row.permissions.delete" text="Delete" position="bottom">
            <p-button color-type="tertiary" icon="trash-2" size="medium" @click="campaignDeleteConfirm = row" />
          </p-tooltip>
        </template>

        <!-- Default -->
        <template v-else>
          {{ column }}
        </template>
      </template>
    </p-table-client>
  </p-section>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AppRequest } from '@/app_request';
import { CampaignResource, CampaignTypeResource } from '@/types/api/campaign';
import { PaginatedResult } from '@/types/api/generic';
import { format } from 'date-fns';
import { TableBody } from '@/components/ui/Table.vue';
import { TabItem } from '@/components/ui/form/types';

interface CampaignTypeResourceMap {
  [key: string]: CampaignTypeResource;
}

@Component({})
export default class extends Vue {
  public waitingForAlwaysOnCampaigns = false;
  public AlwaysOnCampaigns: PaginatedResult<CampaignResource> | null = null;

  public waitingForCampaignTypes = true;
  public campaignTypes: CampaignTypeResourceMap = {};

  public selectedCampaignTab = 'games';

  public campaignTabs: TabItem[] = [
    {
      id: 'games',
      label: 'Games'
    },
    {
      id: 'popups',
      label: 'Popups'
    },
    {
      id: 'ads',
      label: 'ADS'
    }
  ];

  public campaignDeleteConfirm: CampaignResource | null = null;

  public get confirmModalTitle() {
    if (this.campaignDeleteConfirm) {
      return `Are you sure you want to delete "${this.campaignDeleteConfirm?.name}"?`;
    }
    return '';
  }

  public mounted() {
    this.loadAlwaysOnCampaigns();
    this.loadCampaignTypes();
  }

  @Watch('selectedCampaignTab')
  private onCampaignTabChange() {
    this.loadAlwaysOnCampaigns();
  }

  public get AlwaysOnCampaignsBody(): TableBody[] {
    return (
      this.AlwaysOnCampaigns?.data.map((campaign) => {
        return {
          ...campaign,
          typeName:
            Object.keys(this.campaignTypes).length > 0
              ? this.campaignTypes[`${campaign.type}`]?.name ?? 'N/A'
              : undefined,
          creator: campaign.created_by.name,
          created_on: format(new Date(campaign.created_on), 'dd-MM-yyyy')
        };
      }) ?? []
    );
  }

  public async deleteCampaign() {
    if (!this.campaignDeleteConfirm || !this.campaignDeleteConfirm.permissions.delete) {
      return;
    }

    await AppRequest.delete(`/api/v1/campaign/${this.campaignDeleteConfirm.uuid}`);
    await this.loadAlwaysOnCampaigns(false);
  }

  private async loadAlwaysOnCampaigns(shouldWait = true) {
    if (shouldWait) {
      this.waitingForAlwaysOnCampaigns = true;
    }

    const date = new Date();
    const lastYear = date.getFullYear() - 1;
    date.setFullYear(lastYear);

    const response = (
      await AppRequest.get<PaginatedResult<CampaignResource>>('/api/v1/campaign', {
        params: {
          sort: ['created_on:desc', 'name:asc'],
          status: 'active_always',
          ...(this.selectedCampaignTab === 'popups' && { type: ['popupv2'] }),
          ...(this.selectedCampaignTab !== 'popups' && { type_not: ['popupv2'] }),
          device_type: this.selectedCampaignTab === 'ads' ? ['ADS'] : ['ALL_DEVICES', 'MOBILE_ONLY'],
          per_page: 20,
          older_than_year: 1
        }
      })
    ).data;

    this.AlwaysOnCampaigns = response;

    if (shouldWait) {
      this.waitingForAlwaysOnCampaigns = false;
    }
  }

  private async loadCampaignTypes() {
    this.waitingForCampaignTypes = true;

    const campaignTypesResponse = (await AppRequest.get<{ data: CampaignTypeResource[] }>('/api/v1/campaign-type')).data
      .data;
    const campaignTypes: CampaignTypeResourceMap = {};

    campaignTypesResponse.forEach((campaignType) => {
      campaignTypes[campaignType.alias] = campaignType;
    });

    this.campaignTypes = campaignTypes;
    this.waitingForCampaignTypes = false;
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

::v-deep {
  .campaign-name {
    @include component-text-strong;

    color: var(--text-color-headline);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.creator-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.campaign-creation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-extra-small);
}

.decorative-text {
  display: inline-flex;
  align-items: center;
  height: 26px;

  --text-color-default: var(--text-color-help);
}
</style>
