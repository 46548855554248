import { render, staticRenderFns } from "./MediaSelector.vue?vue&type=template&id=4098e712&scoped=true"
import script from "./MediaSelector.vue?vue&type=script&lang=ts"
export * from "./MediaSelector.vue?vue&type=script&lang=ts"
import style0 from "./MediaSelector.vue?vue&type=style&index=0&id=4098e712&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4098e712",
  null
  
)

export default component.exports