<template>
  <p-modal-plain
    position="top"
    :show.prop="true"
    headline="Move to folder"
    @close-request="$emit('close-request')"
    body-padding
    has-footer
  >
    <div class="move">
      <div class="move__icon">
        <p-icon size="extra-large" icon="folder" />
      </div>

      <div class="move__description">Choose the folder you wish to move to</div>

      <p-row v-if="conflictingFolder" justify-content="center">
        <p-column size="medium">
          <p-message type="negative" description="You cannot move folders into itself" /> </p-column
      ></p-row>

      <p-row v-if="saveError" justify-content="center">
        <p-column size="medium"> <p-message type="negative" :description="saveError" /> </p-column
      ></p-row>

      <p-paragraph v-if="lastKnownSearch && !hasValidSearchTerm">Please input at least 3 characters</p-paragraph>

      <p-form-select
        :disabled="loading || loadingSuccess"
        searchable
        filterable
        size="medium"
        placeholder="Search for folder"
        :options="!lastKnownSearch && selectedFolderOption ? [selectedFolderOption] : folders"
        :loadingOptions="loadingOptions"
        v-model="selectedFolder"
        @search="onSearchChange($event.detail[0])"
      />
    </div>

    <p-button slot="footer" color-type="tertiary" @click="$emit('close-request')">Cancel</p-button>

    <p-button
      slot="footer"
      :disabled.prop="!selectedFolder"
      :loading.prop="loading || loadingSuccess"
      :loading-success.prop="loadingSuccess"
      color-type="primary"
      @click="save()"
      >Save</p-button
    >
  </p-modal-plain>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MediaObject } from './types';
import { IElementSelectOption } from '@/interfaces/element';
import { convertMediaResourceToObject } from './utils';
import { AppRequest } from '@/app_request';
import { MediaResource } from '@/types/api/media';
import { Debounce } from 'lodash-decorators/debounce';
import Axios, { CancelTokenSource } from 'axios';

const CancelToken = Axios.CancelToken;

@Component({})
export default class extends Vue {
  @Prop({ type: Array as PropType<MediaObject[]>, required: true })
  public readonly objects!: MediaObject[];

  public loading = false;
  public loadingSuccess = false;
  public loadingOptions = false;

  public folders: IElementSelectOption[] = [];
  public selectedFolder: string | null = null;
  public selectedFolderOption: IElementSelectOption | null = null;
  public lastKnownSearch: string | null = null;
  public saveError: string | null = null;

  public source: CancelTokenSource | null = null;

  public async save() {
    this.loading = true;

    try {
      for (const object of this.objects) {
        const existingObject = (await AppRequest.get<{ data: MediaResource }>(`/api/v1/media/${object.id}`)).data.data;

        await AppRequest.put(`/api/v1/media/${existingObject.id}`, {
          ...existingObject,
          folder_id: this.selectedFolder
        });
      }

      this.loadingSuccess = true;

      setTimeout(() => {
        this.$emit('moved');
        this.$emit('close-request');
      }, 1500);
    } catch (e) {
      if (
        Axios.isAxiosError(e) &&
        e.response?.status === 422 &&
        e.response.data &&
        typeof e.response.data === 'object' &&
        'message' in e.response.data &&
        typeof e.response.data.message === 'string'
      ) {
        this.saveError = e.response.data.message;
        this.loading = false;
        return;
      }

      // Unknown error occurred. Remove the loading state allowing the user to re-attempt.
      this.loading = false;
    }
  }

  @Watch('selectedFolder')
  public onSelected() {
    this.saveError = null;

    if (this.selectedFolder) {
      this.selectedFolderOption = this.folders.find((folder) => folder.value === this.selectedFolder) ?? null;
    } else {
      this.selectedFolderOption = null;
    }
  }

  @Debounce(375)
  public async onSearchChange(search?: string) {
    this.lastKnownSearch = search ?? null;

    if (!search || !this.hasValidSearchTerm) {
      this.folders = [];
      return;
    }

    this.loadingOptions = true;

    if (this.source !== null) {
      this.source.cancel();
      this.source = null;
    }

    this.source = CancelToken.source();

    try {
      const objects = (
        await AppRequest.get<{ data: MediaResource[] }>('/api/v1/media', {
          cancelToken: this.source.token,
          params: {
            ...this.apiExtendedParams,
            search: search.trim()
          }
        })
      ).data.data.map<MediaObject>((item) => convertMediaResourceToObject(item));

      const folders = objects.filter((object) => {
        return object.type === 'folder' && !this.objects.some((existingObject) => existingObject.id === object.id);
      });

      this.folders = folders.map((folder) => {
        return {
          value: folder.id,
          text: folder.name
        };
      });
    } catch (e) {
      // Silcense cancel exceptions
      if (Axios.isCancel(e)) {
        return;
      }

      throw e;
    }

    this.loadingOptions = false;
  }

  public get conflictingFolder(): boolean {
    return this.objects.some((object) => object.id === this.selectedFolder);
  }

  private get apiExtendedParams(): Record<string, string> {
    if (this.$route.params.fastTrackHash) {
      return { ['fast-track']: this.$route.params.fastTrackHash };
    }

    return {};
  }

  public get hasValidSearchTerm(): boolean {
    return this.lastKnownSearch && this.lastKnownSearch.trim().length > 2 ? true : false;
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.move {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap-size-medium);
  width: 900px;
  max-width: 100%;

  &__description {
    @include component-text-helptext;
  }
}
</style>
