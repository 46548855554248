<template>
  <p-modal-plain :show.prop="true" position="top" headline="Media library" @close-request="$emit('close-request')">
    <div class="media" :class="{ 'media--selection': selection }">
      <div class="media__breadcrumb">
        <div
          class="media__breadcrumb-item"
          @click="
            breadcrumb = [];
            searchModel = '';
          "
        >
          Main folder
        </div>
        <Fragment v-for="breadcrumbItem in breadcrumb" :key="breadcrumbItem.id">
          <div class="media__breadcrumb-item" :key="`${breadcrumbItem.id}-separator`">/</div>
          <div
            class="media__breadcrumb-item"
            :class="{ 'media__breadcrumb-item--current': currentFolder?.id === breadcrumbItem.id }"
            :key="`${breadcrumbItem.id}-text`"
            @click="goToBreadcrumb(breadcrumbItem)"
          >
            {{ breadcrumbItem.name }}
          </div>
        </Fragment>
      </div>

      <p-message
        v-if="selectError.message"
        :key="selectError.id"
        :scrollIntoView.prop="true"
        type="negative"
        :description="`Error: ${selectError.message}`"
      />

      <div class="media__header">
        <div class="media__header-left">
          <p-row gap-size="large">
            <div>
              <p-form-element label="Upload and create" v-device-desktop>
                <p-row gap-size="small">
                  <p-button icon="file-plus" size="medium" @click="showUpload = true">Upload file</p-button>

                  <p-tooltip v-if="isFastTrack" text="Not available in fast track">
                    <p-button icon="folder-plus" size="medium" disabled>Create folder</p-button>
                  </p-tooltip>
                  <p-button v-else icon="folder-plus" size="medium" @click="createFolder">Create folder</p-button>
                </p-row>
              </p-form-element>

              <p-form-element label="Create" v-device-mobile>
                <p-row gap-size="small">
                  <p-tooltip v-if="isFastTrack" text="Not available in fast track">
                    <p-button icon="folder-plus" size="medium" disabled>Create folder</p-button>
                  </p-tooltip>
                  <p-button v-else icon="folder-plus" size="medium" @click="createFolder">Create folder</p-button>
                </p-row>
              </p-form-element>
            </div>

            <!-- <div>
              <p-form-element label="Get images and assets">
                <p-row gap-size="small">
                  <p-button size="medium" color-type="secondary">Pexels</p-button>
                  <p-button size="medium" color-type="secondary">Unsplash</p-button>
                </p-row>
              </p-form-element>
            </div> -->
          </p-row>
        </div>

        <div class="media__header-right">
          <p-row justify-content="flex-end" align-items="flex-end">
            <p-form-search
              v-model="searchModel"
              placeholder="Search across all folders"
              size="medium"
              :error="searchModel && !hasValidSearchTerm ? 'Please input at least 3 characters' : ''"
              :autofocus="true"
            />

            <p-form-select
              v-device-desktop
              label="With selected files"
              v-model="selectedAction"
              placeholder="select"
              :options="
                [
                  { text: 'Download', value: 'download' },
                  { text: 'Delete', value: 'delete' },
                  { text: 'Rename', value: 'rename' },
                  { text: 'Move to folder', value: 'move-to-folder' },
                  breadcrumb.length > 0 && { text: 'Move to main folder', value: 'move-to-main-folder' }
                ].filter((item) => item !== null)
              "
              size="small"
              :disabled="selected.length === 0 || isFastTrack"
            />

            <p-form-select
              v-device-mobile
              label="With selected files"
              v-model="selectedAction"
              placeholder="select"
              :options="
                [
                  { text: 'Download', value: 'download' },
                  { text: 'Delete', value: 'delete' },
                  { text: 'Rename', value: 'rename' },
                  breadcrumb.length > 0 && { text: 'Move to main folder', value: 'move-to-main-folder' }
                ].filter((item) => item !== null)
              "
              size="extra-large"
              :disabled="selected.length === 0 || isFastTrack"
            />
          </p-row>
        </div>
      </div>

      <div class="media__body">
        <div class="media__loader" v-if="waitingForObjects">
          <p-loading size="extra-large" />
        </div>

        <p-message
          v-else-if="searchModel && hasValidSearchTerm && objects.length === 0"
          type="info"
          :description="`Your search for '${searchModel}' didn't return any results`"
        />

        <p-message v-else-if="objects.length === 0" type="info" description="No files or folders found" />

        <template v-else>
          <media-card
            v-for="object in objects"
            :key="object.id"
            :selection="selection"
            :object="object"
            :selectionMode="selectionMode"
            :folder="currentFolder"
            :searchTerm="hasValidSearchTerm ? searchModel : undefined"
            @cropped="refresh()"
            @deleted="deleted(object)"
            @goToFolder="goToFolder"
            @select="(object, folderSelection) => select(object, folderSelection)"
            @before-move="onBeforeMove"
          />
        </template>
      </div>
    </div>

    <create-folder
      v-if="showCreateFolder"
      :folder="currentFolder"
      @created="refresh()"
      @close-request="showCreateFolder = false"
    />

    <upload
      v-if="showUpload"
      :folder="currentFolder"
      :extensions="extensions"
      @uploaded="uploaded"
      @close-request="showUpload = false"
    />

    <cropper
      v-if="showCropperFor"
      :object="showCropperFor"
      :folder="currentFolder"
      :initial-width="cropperWidth ?? undefined"
      :initial-height="cropperHeight ?? undefined"
      @close-request="showCropperFor = null"
      @cropped="onCrop"
    />

    <p-modal-confirm
      :show.prop="true"
      v-if="selectedAction === 'delete'"
      confirm-text="delete"
      :callback.prop="deleteObjects"
      @close-request="selectedAction = ''"
    />

    <modal-rename-multiple
      v-if="selectedAction === 'rename'"
      :objects="selected"
      @renamed="onRename"
      @close-request="selectedAction = ''"
    />

    <modal-move-to-folder
      v-if="selectedAction === 'move-to-folder'"
      :objects="selected"
      @moved="onMoved"
      @close-request="selectedAction = ''"
    />

    <p-modal-loader v-if="waitingForDownload || waitingForUpload || waitingForMove" />
  </p-modal-plain>
</template>

<script lang="ts">
import { AppRequest } from '@/app_request';
import { MediaResource } from '@/types/api/media';
import { PropType } from 'vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import MediaCard from './Card.vue';
import ModalRenameMultiple from './ModalRenameMultiple.vue';
import ModalMoveToFolder from './ModalMoveToFolder.vue';
import CreateFolder from './CreateFolder.vue';
import Upload from './Upload.vue';
import Cropper from './Cropper.vue';
import { MediaObject, UploadRequestResponse } from './types';
import { convertMediaResourceToObject, getDefaultExtensions, getFormattedAspectRatio, resizeImage } from './utils';
import axios, { AxiosError } from 'axios';
import { Debounce } from 'lodash-decorators';

@Component({
  inheritAttrs: false,
  components: {
    MediaCard,
    CreateFolder,
    Upload,
    Cropper,
    ModalRenameMultiple,
    ModalMoveToFolder
  }
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) public readonly selection!: boolean;

  @Prop({ type: String as PropType<'file' | 'folder'>, required: false, default: 'file' })
  public readonly selectionMode!: 'file' | 'folder';

  @Prop({ type: Number, required: false, default: undefined })
  public readonly maxWidth!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly maxHeight!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly minWidth!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly minHeight!: number;

  @Prop({ type: String, required: false, default: undefined })
  public readonly aspectRatio!: string;

  @Prop({
    type: Array as PropType<string[]>,
    required: false,
    default: () => getDefaultExtensions()
  })
  public readonly extensions?: string[];

  @Prop({ type: String, required: false, default: undefined })
  public readonly initialOpenedMedia!: string;

  public selectedAction = '';
  public objects: MediaObject[] = [];
  public waitingForObjects = true;
  public showCreateFolder = false;
  public showUpload = false;
  public breadcrumb: MediaObject[] = [];

  public selectError = {
    message: '',
    id: 1
  };

  public waitingForUpload = false;
  public waitingForMove = false;
  public waitingForDownload = false;

  public showCropperFor: MediaObject | null = null;
  public cropperWidth: number | null = null;
  public cropperHeight: number | null = null;

  public searchModel = '';

  public async mounted() {
    // Attempt at opening the folder of the current opened media
    if (this.initialOpenedMedia) {
      try {
        const media = (
          await AppRequest.get<{ data: MediaResource }>('/api/v1/media/lookup', {
            params: { url: this.initialOpenedMedia }
          })
        ).data.data;

        const breadcrumb = (await AppRequest.get<{ data: MediaResource[] }>(`/api/v1/media/${media.id}/breadcrumb`))
          .data.data;

        this.breadcrumb = breadcrumb.map<MediaObject>((item) => convertMediaResourceToObject(item));

        return;
      } catch (e) {
        // Ignore axios errors since we expect these when media is no longer found or access it no longer there
        if (!axios.isAxiosError(e)) {
          throw e;
        }
      }
    }

    // Attempt at opening last opened folder
    try {
      const remembered = sessionStorage.getItem('media-folder');

      if (remembered && typeof remembered === 'string') {
        const breadcrumb = (await AppRequest.get<{ data: MediaResource[] }>(`/api/v1/media/${remembered}/breadcrumb`))
          .data.data;

        const folder = (await AppRequest.get<{ data: MediaResource }>(`/api/v1/media/${remembered}`)).data.data;

        this.breadcrumb = [
          ...breadcrumb.map<MediaObject>((item) => convertMediaResourceToObject(item)),
          convertMediaResourceToObject(folder)
        ];

        return;
      }
    } catch (e) {
      // Ignore axios errors since we expect these when folder is no longer found or access it no longer there
      if (!axios.isAxiosError(e)) {
        throw e;
      }
    }

    this.loadMainFolder();
  }

  public get selected(): MediaObject[] {
    return this.objects.filter((object) => object.selected);
  }

  /**
   * Remove media files before the API call is made.
   * We do this to improve UX so that user should not wait for the moving to take place.
   */
  public onBeforeMove(mediaId: string) {
    this.objects = this.objects.filter((object) => object.id !== mediaId);
  }

  public select(object: MediaObject, folderSelection = false) {
    if (this.selection && object.type === this.selectionMode) {
      this.selectError.message = '';

      // Reject selection if extension doesn't match requested extension
      if (this.selectionMode === 'file' && this.extensions && this.extensions.length > 0) {
        const extension = object.file?.split('.').pop() ?? '';

        if (!this.extensions.includes(extension)) {
          this.selectError = {
            message: `File extension "${extension}" is not allowed. Please select another media file.`,
            id: ++this.selectError.id
          };
          return;
        }
      }

      // Check to see if we need to upsample or downsample the image
      if (
        object.image &&
        (this.maxWidth || this.maxHeight || this.minWidth || this.minHeight || this.aspectRatio) &&
        (object.file?.endsWith('png') || object.file?.endsWith('jpg') || object.file?.endsWith('jpeg'))
      ) {
        const image = new Image();
        image.src = object.file;
        image.onload = () => {
          const itemAspectRatio = getFormattedAspectRatio(image.width, image.height);
          const { cropWidth, cropHeight } = this.getMaxMinCropDimension(
            image,
            this.maxHeight,
            this.maxWidth,
            this.minHeight,
            this.minWidth,
            this.aspectRatio
          );
          // if aspectRatio doesn't match we need to chop
          if (this.aspectRatio !== undefined && itemAspectRatio !== this.aspectRatio) {
            // Show crop
            this.showCropperFor = object;
            this.cropperWidth = cropWidth;
            this.cropperHeight = cropHeight;
            return;
          }

          // check if image too big and/or small
          if (
            (this.maxWidth && image.width > this.maxWidth) ||
            (this.maxHeight && image.height > this.maxHeight) ||
            (this.minWidth && image.width < this.minWidth) ||
            (this.minHeight && image.height < this.minHeight)
          ) {
            const resizedDimension: { w: number; h: number } = {
              w: image.width,
              h: image.height
            };

            let i = 0;
            let resizeAttempts = 0;
            let resizedFailed = false;

            // resize loop -> we will try and resize matching to the validations rules
            // if resized fails then we will ask user to crop the image
            // eslint-disable-next-line no-constant-condition
            while (true) {
              // tracking of resize attempts
              const currentResizeAttempt = resizeAttempts;

              if (this.maxWidth && resizedDimension.w > this.maxWidth) {
                resizedDimension.h = Math.floor((resizedDimension.h / resizedDimension.w) * this.maxWidth);
                resizedDimension.w = this.maxWidth;
                resizeAttempts++;
              }

              if (this.maxHeight && resizedDimension.h > this.maxHeight) {
                resizedDimension.w = Math.floor((resizedDimension.w / resizedDimension.h) * this.maxHeight);
                resizedDimension.h = this.maxHeight;
                resizeAttempts++;
              }

              if (this.minWidth && resizedDimension.w < this.minWidth) {
                resizedDimension.h = Math.floor((resizedDimension.h / resizedDimension.w) * this.minWidth);
                resizedDimension.w = this.minWidth;
                resizeAttempts++;
              }

              if (this.minHeight && resizedDimension.h < this.minHeight) {
                resizedDimension.w = Math.floor((resizedDimension.w / resizedDimension.h) * this.minHeight);
                resizedDimension.h = this.minHeight;
                resizeAttempts++;
              }

              // if the loop doesn't resize anymore then we can break out and use the dimensions
              if (currentResizeAttempt === resizeAttempts) {
                break;
              }

              // failsave break out of loop, so we don't forever try and resize
              if (i === 3) {
                resizedFailed = true;
                // we need to crop the image... we cant resize it properly
                break;
              }
              i++;
            }

            // if we couldn't resize the image properly - then user need to crop it
            if (resizedFailed) {
              this.showCropperFor = object;
              this.cropperWidth = cropWidth;
              this.cropperHeight = cropHeight;
              return;
            }

            this.waitingForUpload = true;

            const canvas = document.createElement('canvas');
            const canvasContext = canvas.getContext('2d');
            canvas.height = image.height;
            canvas.width = image.width;
            canvasContext?.drawImage(image, 0, 0, canvas.width, canvas.height);

            const resizedCanvas = resizeImage(canvas, resizedDimension.w, resizedDimension.h);

            const extension = object.file?.split('.').pop() ?? '';
            const originalName = object.name.split('.').slice(0, -1).join('.');
            const name = `${originalName}-${resizedDimension.w}-${resizedDimension.h}.${extension}`;
            const contentType = extension === 'png' ? 'image/png' : 'image/jpeg';

            resizedCanvas.toBlob(
              async (blob) => {
                try {
                  if (blob) {
                    const uploadRequestResponse = (
                      await AppRequest.post<UploadRequestResponse>(
                        '/api/v1/media/pre-sign-upload',
                        {
                          extension,
                          contentType,
                          contentLength: blob.size
                        },
                        {
                          params: this.apiExtendedParams
                        }
                      )
                    ).data;

                    const formData = new FormData();

                    for (const name in uploadRequestResponse.inputs) {
                      let value = uploadRequestResponse.inputs[`${name}`];

                      if (name === 'key') {
                        value = uploadRequestResponse.url.slice(1);
                      }

                      formData.append(name, value);
                    }

                    formData.append('file', blob);

                    await fetch(uploadRequestResponse.attributes.action, {
                      method: uploadRequestResponse.attributes.method,
                      body: formData
                    });

                    const fileUrl = (
                      await AppRequest.post<{ data: MediaResource }>(
                        '/api/v1/media',
                        {
                          ...(this.currentFolder && { folder_id: this.currentFolder.id }),
                          name,
                          file: uploadRequestResponse.url,
                          type: 'file'
                        },
                        {
                          params: this.apiExtendedParams
                        }
                      )
                    ).data.data.file;

                    this.waitingForUpload = false;

                    this.$emit('select', fileUrl);
                  }
                } catch (e) {
                  if (axios.isAxiosError(e)) {
                    const error = e as AxiosError<{ message?: string }>;

                    if (error.response?.status === 422 && error.response.data.message) {
                      this.selectError = { message: error.response.data.message, id: ++this.selectError.id };
                      this.waitingForUpload = false;
                      return;
                    }

                    throw e;
                  } else {
                    throw e;
                  }
                }
              },
              contentType,
              0.8
            );
          } else {
            this.$emit('select', object.file);
          }
        };
      } else {
        if (object.type === 'folder' && !folderSelection) {
          this.breadcrumb.push(object);
          this.searchModel = '';
        } else {
          this.$emit('select', this.selectionMode === 'file' ? object.file : object.id);
        }
      }
    } else if (object.type === 'folder') {
      this.breadcrumb.push(object);
      this.searchModel = '';
    }
  }

  public async goToFolder(object: MediaObject) {
    try {
      const breadcrumb = (await AppRequest.get<{ data: MediaResource[] }>(`/api/v1/media/${object.id}/breadcrumb`)).data
        .data;

      this.breadcrumb = breadcrumb.map<MediaObject>((item) => convertMediaResourceToObject(item));
      this.searchModel = '';
    } catch (e) {
      // Ignore axios errors since we expect these when media is no longer found or access it no longer there
      if (!axios.isAxiosError(e)) {
        throw e;
      }
    }
  }

  public onMoved() {
    this.refresh();
  }

  public onRename(newNameMap: Record<string, string>) {
    this.objects.forEach((object) => {
      if (Object.prototype.hasOwnProperty.call(newNameMap, object.id)) {
        object.name = newNameMap[object.id];
      }

      object.selected = false;
    });
  }

  public onCrop(object: MediaObject) {
    this.select(object);
  }

  public async uploaded(files: MediaObject[]) {
    await this.refresh();

    if (this.selection && files.length > 0) {
      this.select(files[0]);
    }
  }

  public deleted(object: MediaObject) {
    this.objects = this.objects.filter((item) => item.id !== object.id);
  }

  @Watch('selectedAction')
  public async onSelectedActionChange() {
    if (this.selectedAction === 'download') {
      this.waitingForDownload = true;

      const zipUrl = (
        await AppRequest.post<{ url: string }>('/api/v1/media/download', {
          media_ids: this.selected.map((object) => object.id)
        })
      ).data.url;

      window.location.href = zipUrl;

      this.waitingForDownload = false;
      this.selectedAction = '';
    } else if (this.selectedAction === 'move-to-main-folder') {
      this.waitingForMove = true;

      for (const selected of this.selected) {
        await AppRequest.put(`/api/v1/media/${selected.id}`, {
          name: selected.name,
          folder_id: null,
          ...(selected.file && { file: selected.file })
        });
      }

      this.objects = this.objects.filter((object) => !this.selected.includes(object));

      this.waitingForMove = false;
      this.selectedAction = '';
    }
  }

  public async deleteObjects() {
    await Promise.all(this.selected.map((object) => AppRequest.delete(`/api/v1/media/${object.id}`)));

    this.objects = this.objects.filter((object) => !this.selected.includes(object));
    this.selectedAction = '';
  }

  public goToBreadcrumb(folder: MediaObject) {
    if (this.currentFolder?.id === folder.id) {
      return;
    }

    this.searchModel = '';
    const folderIndex = this.breadcrumb.indexOf(folder);
    this.breadcrumb = this.breadcrumb.filter((breadcrumb) => this.breadcrumb.indexOf(breadcrumb) <= folderIndex);
  }

  @Watch('breadcrumb')
  public async onBreadcrumbChange() {
    this.selectError.message = '';
    this.waitingForObjects = true;

    let folderId: string | null = null;

    if (this.breadcrumb.length > 0) {
      folderId = this.breadcrumb[this.breadcrumb.length - 1].id;
      sessionStorage.setItem('media-folder', folderId);
    } else {
      sessionStorage.removeItem('media-folder');
    }

    const searchParams: { search?: string } = this.hasValidSearchTerm ? { search: this.searchModel } : {};

    this.objects = (
      await AppRequest.get<{ data: MediaResource[] }>(
        '/api/v1/media',
        folderId
          ? { params: { folder: folderId, ...this.apiExtendedParams, ...searchParams } }
          : { params: { ...this.apiExtendedParams, ...searchParams } }
      )
    ).data.data.map<MediaObject>((item) => convertMediaResourceToObject(item));

    this.waitingForObjects = false;
  }

  public async loadMainFolder() {
    this.waitingForObjects = true;
    this.selectError.message = '';

    const searchParams: { search?: string } = this.hasValidSearchTerm ? { search: this.searchModel } : {};

    this.objects = (
      await AppRequest.get<{ data: MediaResource[] }>('/api/v1/media', {
        params: { ...this.apiExtendedParams, ...searchParams }
      })
    ).data.data.map<MediaObject>((item) => convertMediaResourceToObject(item));

    this.waitingForObjects = false;
  }

  public createFolder() {
    this.showCreateFolder = true;
  }

  public async refresh() {
    if (this.breadcrumb.length > 0) {
      await this.onBreadcrumbChange();
    } else {
      await this.loadMainFolder();
    }
  }

  @Watch('searchModel')
  public onSearchDebounced() {
    this.waitingForObjects = true;
    this.debouncedRefresh();
  }

  @Debounce(500)
  private async debouncedRefresh() {
    await this.refresh();
  }

  public get hasValidSearchTerm(): boolean {
    return this.searchModel.trim().length > 2;
  }

  public get currentFolder(): MediaObject | undefined {
    return this.breadcrumb[this.breadcrumb.length - 1];
  }

  public get isFastTrack() {
    return !!this.$route.params.fastTrackHash;
  }

  public get apiExtendedParams(): Record<string, string> {
    if (this.$route.params.fastTrackHash) {
      return { ['fast-track']: this.$route.params.fastTrackHash };
    }

    return {};
  }

  private getAspectRatioFromString(aspectRatio: string): number {
    const [width, height] = aspectRatio.split('/');

    return Number(width) / Number(height);
  }

  // returns the maximum and minimum value from number array
  private getMinMaxValues(arr: number[]) {
    return arr.reduce(([min, max = min], num: number) => [Math.min(num, min), Math.max(num, max)], arr);
  }

  private getMaxMinCropDimension(
    image: HTMLImageElement,
    maxWidth?: number,
    maxHeight?: number,
    minWidth?: number,
    minHeight?: number,
    aspectRatio?: string
  ) {
    let cropWidth = image.width;
    let cropHeight = image.height;
    const imageAspectRatio = image.width / image.height;
    const wantedAspectRatio = aspectRatio ? this.getAspectRatioFromString(aspectRatio) : undefined;

    // default values
    const maxHeightDefault = maxHeight ? maxHeight : 3000;
    const maxWidthDefault = maxWidth ? maxWidth : 2650;
    const minHeightDefault = minHeight ? minHeight : 1;
    const minWidthDefault = minWidth ? minWidth : 1;

    const valuesArray = [maxWidthDefault, maxHeightDefault, minWidthDefault, minHeightDefault].filter(Boolean);
    const [min, max] = this.getMinMaxValues(valuesArray);

    if (image.width > image.height) {
      if (image.height <= max && image.height >= min) {
        cropWidth = image.height;
        cropHeight = Math.floor(image.height / (wantedAspectRatio ?? imageAspectRatio));
      } else if (image.height > max) {
        cropWidth = max;
        cropHeight = Math.floor(max / (wantedAspectRatio ?? imageAspectRatio));
      } else if (image.height < min) {
        cropWidth = Math.floor(min * (wantedAspectRatio ?? imageAspectRatio));
        cropHeight = min;
      }
    }

    if (image.height > image.width) {
      if (image.width <= max && image.width >= min) {
        cropWidth = image.width;
        cropHeight = Math.floor(image.width / (wantedAspectRatio ?? imageAspectRatio));
      } else if (image.width >= max || image.width <= max) {
        cropWidth = max;
        cropHeight = Math.floor(max / (wantedAspectRatio ?? imageAspectRatio));
      } else if (image.width <= min) {
        cropWidth = Math.floor(min * (wantedAspectRatio ?? imageAspectRatio));
        cropHeight = min;
      }
    }

    return {
      cropWidth,
      cropHeight
    };
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.media {
  display: flex;
  width: 100%;
  gap: var(--gap-size-large);
  flex-direction: column;
  color: var(--text-color-default);

  &__breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap-size-extra-small);
    flex-wrap: wrap;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include button-text-large;
      color: var(--text-color-help);
      cursor: pointer;

      &--current {
        @include button-text-large-underlined;
        color: var(--text-color-headline);
        cursor: not-allowed;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 var(--base-size-700);
    flex-wrap: wrap;
    gap: var(--gap-size-small);

    &-right {
      flex-basis: 200px;
      flex-grow: 1;
    }
  }

  &__body {
    position: relative;
    display: flex;
    gap: var(--gap-size-large) var(--gap-size-large);
    padding: var(--gap-size-medium) var(--gap-size-large);
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__loader {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
  }
}

@include for-mobile-only {
  .media {
    &__header,
    &__body {
      padding: 0;
    }
  }
}
</style>
