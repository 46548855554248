<template>
  <div class="topbar" v-if="campaign">
    <!-- Expired -->
    <p-message
      v-if="campaign.resource.state === 'expired'"
      type="warning"
      display-type="banner"
      description="The campaign is no longer active. All registration have been deleted for safety reasons."
    >
      <p-link :href="campaign.resource.live_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Live URL</p-button></p-link
      >
      <p-link :href="campaign.resource.demo_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Demo URL</p-button></p-link
      >
      <p-button size="small" color-type="tertiary">Clear Cache</p-button>
    </p-message>

    <!-- In expire cycle -->
    <p-message
      v-else-if="campaign.resource.state === 'in_expire_cycle'"
      type="warning"
      display-type="banner"
      :description="`The campaign is no longer active. You have ${campaign.daysUntilRetention} days left to export the registrations.`"
    >
      <p-link :href="campaign.resource.live_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Live URL</p-button></p-link
      >
      <p-link :href="campaign.resource.demo_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Demo URL</p-button></p-link
      >
      <p-button size="small" color-type="tertiary">Clear Cache</p-button>
    </p-message>

    <!-- Live -->
    <p-message
      v-else-if="campaign.resource.state === 'live'"
      type="warning"
      display-type="banner"
      :description="`The campaign is currently active and visible to all users. The campaign will expire at ${activeTo}`"
    >
      <p-link :href="campaign.resource.live_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Live URL</p-button></p-link
      >
      <p-link :href="campaign.resource.demo_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Demo URL</p-button></p-link
      >
      <p-button size="small" color-type="tertiary">Clear Cache</p-button>
    </p-message>

    <!-- Demo mode, set to be active -->
    <p-message
      v-else-if="isActiveInFuture"
      type="warning"
      display-type="inline"
      :description="`The campaign is activated and will be made visible to all users at ${activeFrom}`"
    >
      <p-link :href="campaign.resource.live_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Live URL</p-button></p-link
      >
      <p-link :href="campaign.resource.demo_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Demo URL</p-button></p-link
      >
      <p-button size="small" color-type="tertiary">Clear Cache</p-button>
    </p-message>

    <!-- Demo mode, set to be active -->
    <p-message
      v-else
      type="warning"
      display-type="banner"
      description="The campaign is currently not active. The campaign will not be visible to the public, before it has been activated."
    >
      <p-link :href="`/campaign/${campaign.resource.type}/${campaign.resource.id}/publishing`" target="_blank"
        ><p-button size="small" color-type="tertiary">Publish</p-button></p-link
      >
      <p-link :href="campaign.resource.demo_url" target="_blank"
        ><p-button size="small" color-type="tertiary">Demo URL</p-button></p-link
      >
      <p-button size="small" color-type="tertiary">Clear Cache</p-button>
    </p-message>
  </div>
</template>

<script lang="ts">
import { CampaignModel } from '@/models/campaign';
import { format } from 'date-fns';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop({
    type: Object as PropType<CampaignModel>,
    required: true
  })
  public readonly campaign!: CampaignModel;

  public get isActiveInFuture() {
    return this.campaign.resource.active && new Date(this.campaign.resource.active_from).getTime() >= Date.now();
  }

  public get activeFrom() {
    return format(new Date(this.campaign.resource.active_from), 'dd-MM-yyyy HH:mm:ss');
  }

  public get activeTo() {
    return format(new Date(this.campaign.resource.active_to), 'dd-MM-yyyy HH:mm:ss');
  }
}
</script>
