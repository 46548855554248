<template>
  <div class="form">
    <p-message
      v-if="element.properties.errors.length > 0"
      description="The provided validation code is incorrect. Please ensure you're using the accurate code"
      type="negative"
    />
    <div
      class="form-element form-element--verification-code"
      :class="[
        element.properties.classNames,
        {
          'form-element--has-error': element.properties.errors.length > 0,
          'form-element--is-disabled': element.properties.disabled
        }
      ]"
    >
      <div class="input-group">
        <template v-for="(val, index) in valueChars">
          <div
            :key="`input-${index}`"
            class="input"
            :class="{
              'input--error': element.properties.errors.length > 0,
              'input--disabled': element.properties.disabled
            }"
          >
            <input
              type="text"
              :autofocus="element.properties.autofocus && index === 0"
              :class="{ 'v-input--has-value': element.properties.value !== '' }"
              :data-id="index"
              :maxLength="1"
              :ref="inputRefName(index)"
              v-model="valueChars[index]"
              :placeholder="element.properties.placeholder"
              :disabled="element.properties.disabled"
              :readonly="element.properties?.readonly"
              autocomplete="off"
              @keydown="onKeydown"
              @keypress="onInteract"
              @keyup="onKeyup(index, $event)"
              @input="onInput($event.target.value, index)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementVerificationCode } from '@/interfaces/element';
import { debounce } from 'lodash-decorators';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';
import { autobind } from 'core-decorators';
import { cancelBlueprintUpdate } from '@/utility';

@Component({
  name: 'layout-element-verification-code'
})
export default class LayoutElementVerificationCode extends Vue {
  @Prop() public element!: IElementVerificationCode;

  private didInteract = false;
  private didInteractTimer: number | null = null;
  private valueChars: string[] = [];

  created() {
    this.valueChars = Array(this.element.properties.inputNumber).fill('');
  }

  @autobind
  onInput(value: string, index: number) {
    if (isNaN(Number(value))) {
      this.valueChars[index] = ''; // remove the last character if it's not a number
    }

    this.element.properties.value = parseInt(this.valueChars.join(''), 10);
    const nextIndex = value ? index + 1 : index - 1;
    const inputElement = this.$refs[this.inputRefName(nextIndex)] as any;
    if (inputElement && inputElement[0] && inputElement[0].focus) {
      inputElement[0].focus();
    }
  }

  @autobind
  onInteract() {
    this.didInteract = true;

    // Cancel existing blueprint calls so that an update doesn't come through if user types a bit slow
    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      cancelBlueprintUpdate(this.$el);
    }

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange(newValue: number) {
    if (this.didInteract) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );
    }

    if (this.didInteract && this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }

  onKeyup(index: number, e: KeyboardEvent) {
    this.onInteract();

    if (e.key === 'Backspace' && index > 0 && this.valueChars[Number(index)] === '') {
      // Move focus to previous input
      const inputElement = this.$refs[this.inputRefName(index - 1)] as any;
      if (inputElement && inputElement[0]) {
        inputElement[0].focus();
        inputElement[0].select ? inputElement[0].select() : null;
      }
    }
  }

  onKeydown(e: KeyboardEvent) {
    this.onInteract();

    // Allow only numbers and some control keys
    if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'Enter'].includes(e.key)) {
      e.preventDefault();
      return;
    }

    if (e.key === 'Enter') {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_SUBMIT', {
          bubbles: true,
          composed: true
        })
      );
      return;
    }
  }

  inputRefName(id: number) {
    return `code-input-${id}`;
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: var(--gap-size-medium);

  .form-element--verification-code {
    .input-group {
      display: flex;
      max-width: 250px;
      gap: var(--gap-size-small);

      .input {
        height: auto;

        input {
          width: 50px;
          height: 86px;
          max-height: none;
          border: 1px solid var(--color-grey-200);
          box-shadow: none;
          border-radius: 6px;
          background-color: var(--color-grey-100);
          font-size: 50px;
          color: var(--color-grey-900);
          font-weight: var(--font-weight-bold);
          padding: 28px 8px 20px;
          text-align: center;
          line-height: 56px;

          &:hover,
          &:focus {
            background-color: var(--color-grey-200);
          }
        }
      }

      .input--error {
        input {
          border: 1px solid var(--color-red-100);
        }
      }
    }
  }
}
</style>
