let translationTokens = 0;
let translationTokensEnabled = false;

export function setTranslationTokens(tokens: number) {
  translationTokens = tokens;
}

export function getTranslationTokens() {
  return translationTokens;
}

export function setTranslationTokensEnabled(enabled: boolean) {
  translationTokensEnabled = enabled;
}

export function getTranslationTokensEnabled() {
  return translationTokensEnabled;
}
