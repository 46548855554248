<template>
  <p-form-positioner
    :loading="isLoading"
    :disabled="element.properties.disabled"
    :loading-success="isLoadingSuccess"
    :label="element.properties.label"
    :canvas="element.properties.canvas"
    :error="element.properties.errors"
    :canvas-width="element.properties.canvasWidth"
    :canvas-height="element.properties.canvasHeight"
    :title="element.properties.title"
    :image="element.properties.image"
    v-model="element.properties.value"
    @update:modelValue="submit()"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementPositioner } from '@/interfaces/element';

@Component({
  name: 'layout-element-positioner'
})
export default class extends Vue {
  @Prop() public element!: IElementPositioner;

  public isLoading = false;
  public isLoadingSuccess = false;

  public submit() {
    this.isLoading = true;

    this.save()
      .then(() => {
        setTimeout(() => {
          this.isLoadingSuccess = false;
        }, 1000);
      })
      .catch(() => {
        this.isLoadingSuccess = false;
        this.isLoading = false;
      });
  }

  public save() {
    return new Promise<void>((resolve) => {
      const webComponents = document.querySelectorAll('p-button[type="default"][color-type="primary"]');

      if (webComponents.length > 0) {
        const button = webComponents[webComponents.length - 1];

        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.attributeName === 'loading') {
              const isLoading = button.hasAttribute('loading');
              if (!isLoading) {
                observer.disconnect();
                this.isLoadingSuccess = true;
                this.isLoading = false;
                resolve();
              }
            }
          });
        });

        observer.observe(button, {
          attributes: true
        });

        if (button instanceof HTMLElement) {
          button.click();
        }
      }
    });
  }
}
</script>
